import { filterTabs } from "../utils/permissions";
import { getContractTypeNameByProductTypeId } from "./contractProductType";

export function isContractStatusEffective(status) {
  return status === '有効'
}

export function getContractTabsByContractType(
  contractTypeNameOrProductTypeId,
  clientId,
  contractId,
  tentative,
  status
) {
  const shouldDisableTerminationTab = !isContractStatusEffective(status)
  let contractType = contractTypeNameOrProductTypeId
  if (typeof contractTypeNameOrProductTypeId === 'number') {
    // it means this is the id
    contractType = getContractTypeNameByProductTypeId(contractTypeNameOrProductTypeId)
  }
  const params = { type: contractType, id: contractId };
  const cancellation = {
    title: '契約解除',
    to: { name: 'contract-cancellation', params },
    disabled: shouldDisableTerminationTab
  }
  const transfer = {
    title: '譲渡相続',
    to: { name: 'contract-transfer', params },
    disabled: shouldDisableTerminationTab
  }

  let tabs = [];
  switch (contractType) {
    case 'FP':
    case 'PW':
    case 'SP':
      tabs = [
        {
          title: '利用状況',
          to: { name: 'usage-situation-sp-pw', params },
        },
        {
          title: '基本情報',
          to: { name: 'basic-infomation-contract', params },
        },
        {
          title: '契約時情報',
          to: { name: 'contract-time-information', params },
        },
        cancellation,
        transfer
      ];
      break;
    case 'MW':
      tabs = [
        {
          title: '利用状況',
          to: { name: 'usage-situation-mw', params },
        },
        {
          title: '基本情報',
          to: { name: 'basic-infomation-contract', params },
        },
        {
          title: '契約時情報',
          to: { name: 'contract-time-information', params },
        },
        cancellation,
        transfer
      ];
      break;
    case 'VM':
      tabs = [
        {
          title: '利用状況',
          to: { name: 'usage-situation-vm', params },
        },
        {
          title: '基本情報',
          to: { name: 'basic-infomation-contract', params },
        },
        {
          title: '契約時情報',
          to: { name: 'contract-time-information', params },
        },
      ];
      if (!tentative) {
        tabs.push(
          cancellation,
          transfer)
      }
      break;
    case 'VS':
      tabs = [
        {
          title: '利用状況',
          to: '/contract/vs-vs/' + contractId + '/VS?clientId=' + clientId + '&contractId=' + contractId ,
        },
        {
          title: '基本情報',
          to:  '/contract/basic-infomation/' +contractId + '/VS?clientId=' + clientId + '&contractId=' + contractId,
        },
        {
          title: '契約時情報',
          to: '/contract/contract-time-information/' +contractId + '/VS?clientId=' + clientId + '&contractId=' + contractId,
        },
        {
          title: '契約解除',
          to: '/contract/contract-cancellation/' +contractId + '/VS?clientId=' + clientId + '&contractId=' + contractId,
          disabled: shouldDisableTerminationTab
        },
        {
          title: '譲渡相続',
          to: '/contract/contract-transfer/' +contractId + '/VS?clientId=' + clientId + '&contractId=' + contractId,
          disabled: shouldDisableTerminationTab
        },
      ];
      break;
    default:
      break;
  }
  return filterTabs(tabs);
}
