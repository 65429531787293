<template>
  <div>
    <template>
      <v-tabs v-model="tab" align-with-title>
        <v-tabs-slider color="#13ACE0"></v-tabs-slider>
        <v-tab
          v-for="(item, idx) in TabName"
          :key="idx"
          :to="item.to"
          :disabled="item.disabled"
          class="page-title-tab"
          >{{ item.title }}
        </v-tab>
      </v-tabs>
    </template>
  </div>
</template>

<script>
import router from '@/router';
import { mapActions, mapMutations } from 'vuex';
import gql from 'graphql-tag';
import { handlErrorView, getCopyErrorTextView } from '@/constants/functions'
import {toAltRouteIfTabMissing} from '@/utils/permissions'
import {getContractTabsByContractType} from '@/constants/contractTabs'

export default {
  name: 'contract-details-tab',
  data() {
    return {
      contractId: null,
      tab: 0,
      clientId: null,
      clientName: null,
      clientType: null,
      tentative: null,
      contractType: router.currentRoute.params.type,
      TabName: [],
    };
  },
  methods: {
    handlErrorView,
    getCopyErrorTextView,
    ...mapActions(['VMComtractUsageList']),
    ...mapMutations(['setCopyErrorText']),

    run() {
      this.TabName = getContractTabsByContractType(
        this.contractType,
        this.clientId,
        this.contractId,
        this.tentative,
        this.status
      )
      toAltRouteIfTabMissing(this.TabName)
    },
    async getInfoContract() {
      const query = `
            query($contractId: Int!) {
              contract(id: $contractId) {
                tentative
                status
                client {
                  id
                  name
                  type
                }
              }
            }
          `
      const variables = {
            contractId: parseInt(this.$route.params.id),
          }
      await this.$apollo
        .mutate({
          mutation: gql`${query}`,
          variables: variables,
          fetchPolicy: 'no-cache'
        })
        .then(data => {
          this.clientId = data.data.contract.client.id
          this.clientName = data.data.contract.client.name
          this.clientType = data.data.contract.client.type
          this.tentative = data.data.contract.tentative
          this.status = data.data.contract.status
          document.title = `${this.clientName}:${this.$route.params.type}契約`
        })
        .catch(async error => {
          this.setCopyErrorText(this.getCopyErrorTextView(query, variables, error.graphQLErrors))
          const errorTmp = await handlErrorView(error.graphQLErrors, this.setPermissionUser, this.setRoleAdminUser)
          if (errorTmp) {
            this.setAlertError(errorTmp, { root: true })
          }
        });
    },
  },
  async mounted() {
    await this.getInfoContract();
    this.contractId = parseInt(this.$route.params.id)
    await this.run();
  },
};
</script>

<style lang="scss" scoped>
.v-tab--active {
  background-color: #ffffff !important;
  color: #13ace0 !important;
}

.v-tab--active:hover {
  background-color: #ceeffb !important;
  color: #13ace0 !important;
}

.v-tab {
  background-color: #ffffff;
  color: #aaaaaa;
  font-weight: 600;
}

.v-tab:hover {
  background-color: #eeeeee;
  color: #aaaaaa;
}
</style>
<style lang="scss" scoped></style>
