<template>
  <div>
    <div class="py-5 px-10 header-main d-flex justify-space-between">
      <div class="d-flex justify-space-between w-100">
        <h1 class="page-title-main d-flex justify-space-between">
          {{clientName}}:
          <div>
            <slot name="title"></slot>
            <v-btn small class="btn-crm-primary mx-2 btn-cus" @click="openCustomerDetail"> 会員情報を開く </v-btn>
          </div>
        </h1>

        <div class="d-flex justify-end">
          <slot name="cancel"></slot>
          <slot name="send"></slot>
        </div>
      </div>
      <slot name="btn-right"></slot>
    </div>
    <!-- <contract-detail-tab></contract-detail-tab> -->
    <ContractDetailTabMW />
    <slot name="default" :isEffective="isEffective"></slot>
  </div>
</template>
<script>
import ContractDetailTabMW from './MW/contractDetailTabMW.vue';
// import contractDetailTab from './Sp_Ep/contractDetailTab';
import gql from 'graphql-tag';
import { handlErrorView, getCopyErrorTextView } from '@/constants/functions'
import { mapMutations } from 'vuex'
import {isContractStatusEffective} from '@/constants/contractTabs'

export default {
  components: { ContractDetailTabMW },
  name: 'contract-details',
  data() {
    return {
      VMType: false,
      tab: 0,
      clientId: null,
      clientType: null,
      clientName: null,
      contract: null
    };
  },
  computed: {
    isEffective() {
      return isContractStatusEffective(this.contract?.status)
    }
  },
  created(){
    this.getInfoContract();
    if (this.$route.params.type === 'VM') {
      this.VMType = true
    }
  },
  methods: {
    handlErrorView,
    getCopyErrorTextView,
    ...mapMutations([
      'setCopyErrorText',
    ]),
    async getInfoContract() {
      const query = `
            query($contractId: Int!) {
              contract(id: $contractId) {
                status
                client {
                  id
                  name
                  type
                }
              }
            }
          `
      const variables = {
            contractId: parseInt(this.$route.params.id),
          }
      await this.$apollo
        .mutate({
          mutation: gql`${query}`,
          variables: variables,
          fetchPolicy: 'no-cache'
        })
        .then(data => {
          this.clientId = data.data.contract.client.id
          this.clientName = data.data.contract.client.name
          this.clientType = data.data.contract.client.type
          this.contract = data.data.contract
          document.title = `${this.clientName}:${this.$route.params.type}契約`
        })
        .catch(async error => {
          this.setCopyErrorText(this.getCopyErrorTextView(query, variables, error.graphQLErrors))
          const errorTmp = await handlErrorView(error.graphQLErrors, this.setPermissionUser, this.setRoleAdminUser)
          if (errorTmp) {
            this.setAlertError(errorTmp, { root: true })
          }
        });
    },
    openCustomerDetail() {
      window.open(`/customer-details/${this.clientId}?typeClient=${this.clientType}`)
    }
  },
};
</script>

<style lang="scss" scoped>
.w-100 {
  width: 100%;
}
.header-main {
  background-color: #f5f5f5 !important;
  .btn-crm-primary {
    border: 0.5px solid #1873d9;
  }
}
.btn-cus {
  height: 32px !important;
  ::v-deep {
    .v-btn__content {
      color: #1873D9 !important;
      font-weight: 600 !important;
      font-size: 12px !important;
    }
  }
}
</style>
