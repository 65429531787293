<template>
  <div>
    <contract-detail>
      <template v-slot:title>{{ $route.params.type }}契約</template>
      <template v-slot:btn>
        <v-btn small class="btn-crm-primary mx-2" target="_blank"
        :to="'/customer-details/' + getBasicInfors.clientId + '?typeClient=' + getBasicInfors.client.type" > 会員情報を開く </v-btn>
      </template>
      <template v-slot:default>
        <div class="mx-10 w-75">
          <div class="d-flex justify-end">
            <v-btn small class="t-btn--red-dark" @click="$windowClose">
              <v-icon>mdi-close</v-icon>
              <span>&emsp;閉じる</span>
            </v-btn>
            <v-btn small class="t-btn--prm ml-3" @click="actionUpdate()" :disabled="!checkPerUser">
              保存
            </v-btn>
          </div>
          <v-card class="my-2 pa-5 ml-1 mt-3">
            <div>
              <h3 class="heading-contract mb-3">契約基本情報</h3>
              <v-row class="ml-16">
                <v-col :cols="3">
                  <h5>ステータス</h5>
                  <h4 class="mt-1">{{ getBasicInfors.status }}</h4>
                </v-col>
                <v-col :cols="3">
                  <h5>フェーズ</h5>
                  <v-select
                    :value="getBasicInfors.phase"
                    @change="changeDataPhaseId($event)"
                    :items="getEnum"
                    item-value="id"
                    item-text="name"
                    hide-details
                    class="mt-1"
                    :disabled="!checkPerUser"
                  ></v-select>
                </v-col>
              </v-row>
              <v-row class="ml-16">
                <v-col :cols="3">
                  <h5>契約番号</h5>
                  <h4 class="mt-1">{{ getBasicInfors.contractNumber }}</h4>
                </v-col>
                <v-col :cols="3">
                  <h5>契約年数</h5>
                  <h4 class="mt-1">{{ getBasicInfors.years }} 年</h4>
                </v-col>
              </v-row>

              <!-- type SP and FP -->
              <v-row
                class="ml-16"
                v-if="$route.params.type == 'SP' || $route.params.type == 'FP'"
              >
                <v-col :cols="3">
                  <h5>年間ポイント数(SP・FP)</h5>
                  <v-text-field
                    type="number"
                    dense
                    @change="changeDataBasePoint($event)"
                    :value="getBasicInfors.basePoint"
                    :disabled="!checkPerUser"
                  ></v-text-field>
                </v-col>
                <v-col :cols="3">
                  <h5>追加購入単価(SP・FP)</h5>
                  <v-text-field
                    dense
                    type="number"
                    @change="changeAddtionalPoint($event)"
                    :value="getBasicInfors.additionalPointPrice"
                    :disabled="!checkPerUser"
                  ></v-text-field>
                </v-col>
              </v-row>
              <!-- and type SP and FP -->

              <!-- type mw -->
              <v-row class="ml-16" v-if="$route.params.type == 'MW'">
                <v-col :cols="3">
                  <h5>MW交換手数料</h5>
                  <v-text-field
                    dense
                    type="number"
                    @change="changeMwExChangeFee($event)"
                    :value="getBasicInfors.mwExchangeFee"
                    :disabled="!checkPerUser"
                  ></v-text-field>
                </v-col>
              </v-row>
              <!-- and type MW -->

              <!-- type VS -->
              <v-row class="ml-16" v-if="$route.params.type == 'VS'">
                <v-col :cols="3">
                  <h5>利用開始日</h5>
                  <div class="d-flex align-center w-50">
                    <div v-for="item in listDate" :key="item.id">
                      <v-menu
                        v-model="menuDate[listDate.indexOf(item)]"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on }">
                          <div class="d-flex justify-start">
                            <v-text-field
                              readonly
                              dense
                              :value="getBasicInfors.loginAllowedDate"
                              @change="changeLoginAllowDate($event)"
                              v-on="on"
                              :disabled="!checkPerUser"
                            ></v-text-field>
                          </div>
                        </template>
                        <v-date-picker
                          v-model="getBasicInfors.loginAllowedDate"
                          :first-day-of-week="0"
                          :locale="$i18n.locale"
                          scrollable
                          @input="menuDate[listDate.indexOf(item)] = false"
                          @change="changeLoginAllowDate($event)"
                          class="v-date-picker-custom"
                          :disabled="!checkPerUser"
                        ></v-date-picker>
                      </v-menu>
                    </div>
                  </div>
                </v-col>
              </v-row>
              <!-- type VS -->

              <v-row class="ml-16">
                <v-col :cols="3">
                  <h5>契約満了日</h5>
                  <div>{{ dateFormat(getBasicInfors.endDate) }}</div>
                </v-col>
                <v-col :cols="3">
                  <h5>解約処理日時</h5>
                  <div>{{ getBasicInfors.terminatedAt | formatDateTime }}</div>
                </v-col>
              </v-row>

              <v-row class="ml-16">
                <v-col :cols="6">
                  <h5>備考</h5>
                  <v-textarea
                    :value="getRemark(getBasicInfors.remarks)"
                    @change="changeRemark($event)"
                    outlined
                    name="input-7-4"
                    class="mb-1 mt-3"
                    v-model="getBasicInfors.remarks"
                    :rules="[$rules.checkTextAreaLength()]"
                    :disabled="!checkPerUser"
                  ></v-textarea>
                </v-col>
              </v-row>
            </div>
          </v-card>
        </div>
      </template>
    </contract-detail>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import contractDetail from '../../ContractDetail.vue';
import checkNumber from '@/utils/validate.js';
import moment from 'moment';
import { checkPermissionUserCurrent } from '@/utils/permissions';
export default {
  components: { contractDetail },
  name: 'BasicInformation',
  data() {
    return {
      checkPerUser: checkPermissionUserCurrent(this.$router.currentRoute),
      changeDate: new Date().toISOString().substr(0, 10),
      date: [],
      menuDate: [],
      menuDate1: [],
      listDate: [{ id: 1, date: new Date().toISOString().substr(0, 10) }],
      phaseId: null,
      basePoint: null,
      additionalPointPrice: null,
      mwExchangeFee: null,
      loginAllowedDate: '',
      menuStartDate: false,
      menuApplicationDate: false,
      menuContractDate: false,
      contractStartDate: new Date().toISOString().substr(0, 10),
      applicationDate: new Date().toISOString().substr(0, 10),
      contractDate: new Date().toISOString().substr(0, 10),
      numberRules: [(v) => checkNumber(v) || 'nhap so'],
      status: '契約中',
      phase: 1,
      ContractNumber: 'XXXXXX',
      ContractYears: 'X年',
      NumberOfPointsPerYear: '',
      AdditionalPurchasePrice: '',
      exchangeHand: '',
      UseStartDay: 'XXXX/XX/XX',
      ExpirationDate: 'XXXX/XX/XX',
      remarks: 'Tbos,salesforceからの移行データなど',
      updateData: {},
      changeStatus: false
    };
  },
  watch:{
    changeStatus(){
      this.updateData = {
        // phaseId: this.getBasicInfors ? this.getBasicInfors.phase.id : '',
        phaseId: this.getBasicInfors ? this.getBasicInfors.phase?.id : '',
        mwExchangeFee: this.getBasicInfors ? this.getBasicInfors.mwExchangeFee : '',
        remarks: this.getBasicInfors ? this.getBasicInfors.remarks : '',
        basePoint: this.getBasicInfors ? this.getBasicInfors.basePoint : '',
        additionalPointPrice: this.getBasicInfors ? this.getBasicInfors.additionalPointPrice : '',
        loginAllowedDate: this.getBasicInfors ? this.getBasicInfors.loginAllowedDate : '',
      }
    }
  },
  mounted() {
    this.basicInformationList().then(this.changeStatus = true);
    this.basicInformationContractPhaseList();
    // this.show()
  },
  computed: {
    ...mapGetters(['getBasicInfors', 'getEnum']),
  },
  methods: {
    ...mapActions([
      'basicInformationList',
      'basicInformationContractPhaseList',
      'basicInformationUpdate',
    ]),
    ...mapMutations(['setDataUpdateBasicInfor', 'setPermissionUser', 'setRoleAdminUser']),
    getLoginAllow(data) {
      console.log(data);
      return data;
    },

    getDataPhaseId(data) {
      this.phaseId = data;
    },

    getDataBasePoint(data) {
      this.basePoint = data;
    },

    getChangeAddtional(data) {
      this.additionalPointPrice = data;
    },

    getMwExChangeFee(data) {
      this.mwExchangeFee = data;
    },

    getRemark(data) {
      this.remarks = data;
    },

    changeDataPhaseId(event) {
      this.updateData.phaseId = event;
      this.phaseId = event;
    },

    changeDataBasePoint(event) {
      this.updateData.basePoint = event;
    },

    changeAddtionalPoint(event) {
      this.updateData.additionalPointPrice = event;
    },

    changeMwExChangeFee(event) {
      this.updateData.mwExchangeFee = parseInt(event);
    },

    changeLoginAllowDate(event) {
      this.updateData.loginAllowedDate = event;
    },

    changeRemark(event) {
      this.updateData.remarks = event;
      this.remarks = event;
    },

    actionUpdate() {
      this.setDataUpdateBasicInfor({ dataUpdate: this.updateData });
      this.basicInformationUpdate();
    },
    dateFormat(value) {
      return moment(value).format('yyyy/MM/DD');
    }
  },
};
</script>

<style lang="scss" scoped>
h3 {
  font-size: 20px !important;
  color: #004f8b;
  font-weight: bold;
}
h4 {
  font-size: 14px;
  color: #444444;
  font-weight: 500;
}
h5 {
  font-size: 9px;
  font-weight: 600;
  color: #000 !important;
}
.w-75 {
  width: 75%;
}

::v-deep {
  .v-textarea {
    .v-input__control {
      border-radius: 7px;
    }
    textarea {
      min-height: 218px !important;
      color: #444444 !important;
    }
  }
  .btn-crm-primary {
    .v-btn__content {
      font-size: 12px !important;
      color: #1873d9;
    }
  }
  input[type='date']::-webkit-calendar-picker-indicator {
    display: none;
  }

  input[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none !important;
  }
  .row:nth-child(7) {
    .col-3:nth-child(1) {
      .theme--light.v-text-field > .v-input__control > .v-input__slot:before {
        border: none !important;
      }
    }
  }

  .v-btn {
    font-size: 14px !important;
    height: 32px !important;
    i {
      font-size: 14px;
    }
  }
  .t-btn--prm {
    width: 68px !important;
  }
  .v-input--is-dirty {
    font-size: 14px !important;
  }
  .border-hiden
    .theme--light.v-text-field
    > .v-input__control
    > .v-input__slot:before {
    display: none !important;
  }
  .border-hiden
    .theme--light.v-text-field
    > .v-input__control
    > .v-input__slot::after {
    display: none !important;
  }
}
</style>
